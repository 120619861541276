$toolbar-height: 64px;
$gutter-size: 1.66em;

// PALETTE
$md-ca_accent: (
  50: #e3f6f2,
  100: #b9eade,
  200: #8bdcc8,
  300: #5dcdb1,
  400: #3ac3a1,
  500: #17b890,
  600: #14b188,
  700: #11a87d,
  800: #0da073,
  900: #079161,
  A100: #beffe6,
  A200: #8bffd2,
  A400: #58ffbe,
  A700: #3fffb4,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-ca_primary: (
  50: #eceef0,
  100: #ced5d8,
  200: #aeb9bf,
  300: #8e9da5,
  400: #758891,
  500: #5d737e,
  600: #556b76,
  700: #4b606b,
  800: #415661,
  900: #30434e,
  A100: #9ddaff,
  A200: #6ac7ff,
  A400: #37b3ff,
  A700: #1eaaff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-ca_warn: (
  50: #f6e7ea,
  100: #e8c4cb,
  200: #d99ca8,
  300: #ca7485,
  400: #be576b,
  500: #b33951,
  600: #ac334a,
  700: #a32c40,
  800: #9a2437,
  900: #8b1727,
  A100: #ffc1c8,
  A200: #ff8e9b,
  A400: #ff5b6e,
  A700: #ff4157,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);

$humidor-primary: (
  50: #e2e0f3,
  100: #b6b3e0,
  200: #8680cc,
  300: #564db8,
  400: #3126a8,
  500: #0d0099,
  600: #0b0091,
  700: #090086,
  800: #07007c,
  900: #03006b,
  A100: #9a9aff,
  A200: #6767ff,
  A400: #3434ff,
  A700: #1a1aff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
/* For use in src/lib/core/theming/_palette.scss */

$humidor-accent: (
  50: #e5f4f3,
  100: #bee4e1,
  200: #93d3cd,
  300: #67c1b8,
  400: #47b3a9,
  500: #26a69a,
  600: #229e92,
  700: #1c9588,
  800: #178b7e,
  900: #0d7b6c,
  A100: #adfff3,
  A200: #7affec,
  A400: #47ffe4,
  A700: #2dffe0,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$humidor-warn: (
  50: #fce0ec,
  100: #f8b3cf,
  200: #f480b0,
  300: #ef4d90,
  400: #eb2678,
  500: #e80060,
  600: #e50058,
  700: #e2004e,
  800: #de0044,
  900: #d80033,
  A100: #ffffff,
  A200: #ffcdd5,
  A400: #ff9aab,
  A700: #ff8096,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
