@use './variables/colors';
@use './variables/sizes';
@use './onboarding/onboarding';
@use './table/table';
@use './utils/utils';
@import './variables/colors';
/* You can add global styles to this file, and also import other style files */

body {
  background-color: #eedacc55;
}
.mat-tab-group {
  min-width: 75vw;
  min-height: 75vh;
}

button.success span {
  color: white;
}
button.success {
  background-color: $success;
}
button.danger {
  background-color: $danger !important;
  span {
    color: white;
  }
}
button.info {
  background-color: $info;
}
button.warning {
  background-color: $warning;
}

button.success:hover {
  background-color: $success-hover;
}
button.danger:hover {
  background-color: $danger-hover;
}
button.info:hover {
  background-color: $info-hover;
}
button.warning:hover {
  background-color: $warning-hover;
}
.edit-btn span {
  color: $warning;
}
.edit-btn:hover span {
  color: $warning-hover;
}
.cancel-btn span {
  color: $danger;
}
.cancel-btn:hover span {
  color: $danger-hover;
}

.mat-success {
  --mdc-chip-label-text-color: #fff;
  background-color: $success !important;
}

.mat-failure {
  --mdc-chip-label-text-color: #fff;
  background-color: $danger !important;
}

.settings-container {
  position: absolute;
  left: 0;
  top: 0;
}
.settings-sidenav {
  padding-top: 78px;
}

.mat-list-text span {
  font-size: 0.6em;
  font-weight: lighter;
}

button.mat-button.sm {
  margin-left: 0;
  margin-right: 0;
  width: auto;
}

.additional-control {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

// form submit button style
button[type='submit'].mat-raised-button {
  margin-top: sizes.$margin-lg;
}

a.forgot-password-link {
  width: 100%;
  text-align: right;
  padding: 0;
  color: $gray;
  cursor: pointer;
  margin-bottom: 2em;
}

h1.form-title {
  max-width: sizes.$onboarding-text-width;
  font-size: 2em;
  font-weight: bold;
}

p.form-instructions {
  max-width: sizes.$onboarding-text-width;
  color: $gray;
}
.cdk-overlay-pane {
  margin-bottom: 2vh !important;
}
/*
 * Dashboard widgets
*/

// card header
div.card-container {
  width: 100%;
  margin-bottom: 5vh;
}
.mat-card {
  display: inline-block !important;
}

div.header-container {
  display: flex;
  flex: row nowrap;
  justify-content: flex-start;
  align-content: center;
  span {
    margin-left: 2em;
    line-height: 2em;
  }
}

/*
 * Menu
*/
mat-selection-list.mat-selection-list.mat-list-base.menu-list {
  padding: 0;
  margin: 0;
  overflow: hidden;
}

// fix menu section at bottom
.bottom {
  position: absolute;
  bottom: 0 !important;
  min-width: sizes.$menu-min-width !important;
}

// adds some width to navigation panel
mat-list-option {
  padding-right: sizes.$margin-lg !important;
}

mat-list-option[aria-selected='true'] {
  // TODO: implement definitive styling for selected menu item here
  border-left: sizes.$border-width-lg solid colors.$black;
}
.mat-tab-body-wrapper {
  padding: 2rem;
}
.project-detail-category-heading {
  margin-top: 0;
}
mat-divider.mat-divider.menu-divider {
  margin-left: 0;
  padding-left: 0;
  width: 90%;
}
mat-drawer-container.mat-drawer-container {
  height: 100vh;
  position: absolute;
  width: 100%;
}
.mat-expansion-panel {
  margin-bottom: 2em !important;
}

.addPadding {
  margin-top: 2em !important;
}
.addHolidayForm {
  height: inherit;
  mat-checkbox.mat-checkbox + mat-form-field.mat-form-field {
    margin-left: 2em;
  }
  mat-form-field.mat-form-field + mat-form-field.mat-form-field {
    margin-left: 2em;
  }
}
.send-email-overlay div .mat-form-field {
  width: 100%;
}
.groupInfo p {
  display: inline-block;
  margin-right: 1em;
  line-height: 24px;
  vertical-align: middle;
}
.groupInfo p.subtle {
  vertical-align: baseline;
}
button.mat-mini-fab {
  width: 24px;
  height: 24px;
  line-height: 14px;
  .material-icons {
    font-size: 14px;
  }
}
// SUCCESS FAB BUTTONS
button.mat-mini-fab.mat-accent.success {
  background-color: $success;
  span {
    color: $white;
  }
}
button.mat-mini-fab.mat-accent.success:hover {
  background-color: $success-hover;
  span {
    color: $white;
  }
}

// .mat-grid-tile:hover {
//   cursor: pointer;
// }
// .mat-grid-tile.selected {
//   border: 2px solid $success;
// }
// .mat-grid-tile.unselected {
//   border: 1px solid grey;
// }
.mat-mini-fab .mat-button-wrapper {
  line-height: 14px !important;
  padding: 4px 0 !important;
}
button.mat-mini-fab.success {
  background-color: $success;
  .mat-icon {
    color: $black;
  }
}
button.mat-mini-fab.warning {
  background-color: $warning;
  .mat-icon {
    color: $black;
  }
}
button.mat-mini-fab.danger {
  background-color: $danger;
  .mat-icon {
    color: $black;
  }
}
button.mat-mini-fab.success:hover {
  background-color: $success-hover;
  .mat-icon {
    color: $white;
  }
}
button.mat-mini-fab.warning:hover {
  background-color: $warning-hover;
  .mat-icon {
    color: $white;
  }
}
button.mat-mini-fab.danger:hover {
  background-color: $danger-hover;
  .mat-icon {
    color: $white;
  }
}
.mat-typography p {
  margin: 0;
}
.ca-filebox-uploaded {
  display: block;
  margin: 2em 0;
}
.ca-filebox-uploaded div {
  display: block;
}
.back-button {
  margin-bottom: 2em !important;
}

.two-fa-setup {
  max-width: 25vw;
}

.logo {
  max-width: 90%;
  padding: 2em;
}

// status badges
.mat-chip.PENDING {
  background-color: $warning;
}
.mat-chip.HOMOLOGATED {
  background-color: $success;
}
.mat-chip.ARCHIVED {
  background-color: $gray;
}
.mat-chip.CANCELLED {
  background-color: $danger;
}
a.warning {
  color: $warning;
  cursor: pointer;
  text-decoration: none !important;
  margin-right: 1rem;
}
a.warning:hover {
  text-decoration: underline !important;
  color: $warning-hover;
}
.error-snackbar {
  background-color: $danger;
}

.success-snackbar {
  background-color: $success;
}

.info-snackbar {
  background-color: $info;
}
