// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// @import './assets/fonts/SharpGroteskBook20.woff2';
// @import './assets/fonts/SharpGroteskBook20.woff2';
@font-face {
  font-family: 'Body Font';
  // font-style: bold;
  // font-weight: 700;
  src: url(./assets/fonts/SharpGroteskBook20.woff2) format('woff2'),
    url(./assets/fonts/SharpGroteskBook20.woff) format('woff');
}
@font-face {
  font-family: 'Title Font';
  // font-style: bold;
  // font-weight: 700;
  src: url(./assets/fonts/SharpGroteskBook22.woff2) format('woff2'),
    url(./assets/fonts/SharpGroteskBook22.woff) format('woff');
}

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import './styles/var';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$gdpr-tool-primary: mat.define-palette($md-ca_primary);
$gdpr-tool-accent: mat.define-palette($md-ca_accent, A200, A100, A400);

// The warn palette is optional (defaults to red).
$gdpr-tool-warn: mat.define-palette($md-ca_warn);

$title-text-level: mat.define-typography-level(
  $font-family: 'Title Font',
  $font-weight: 400,
  $font-size: 1rem,
  $line-height: 1,
  $letter-spacing: normal,
);

$body-text-level: mat.define-typography-level(
  $font-family: 'Body Font',
  $font-weight: 400,
  $font-size: 1rem,
  $line-height: 1,
  $letter-spacing: normal,
);

$humidor-typography-config: mat.define-typography-config(
  /* native level = None */ $headline-1:
    mat.define-typography-level(
      $font-family: 'Title Font',
      $font-weight: 700,
      $font-size: 1.8rem,
      $line-height: 1,
      $letter-spacing: normal,
    ),
  /* native level = None */ $headline-2:
    mat.define-typography-level(
      $font-family: 'Title Font',
      $font-weight: 400,
      $font-size: 1.4rem,
      $line-height: 1,
      $letter-spacing: normal,
    ),
  /* native level = None */ $headline-3:
    mat.define-typography-level(
      $font-family: 'Title Font',
      $font-weight: 400,
      $font-size: 1.2rem,
      $line-height: 1,
      $letter-spacing: normal,
    ),
  /* native level = None */ $headline-4:
    mat.define-typography-level(
      $font-family: 'Title Font',
      $font-weight: 400,
      $font-size: 1rem,
      $line-height: 1.1,
      $letter-spacing: normal,
    ),
  /* native level = h1 */ $headline-5:
    mat.define-typography-level(
      $font-family: 'Title Font',
      $font-weight: 400,
      $font-size: 1.88rem,
      $line-height: 1,
      $letter-spacing: normal,
    ),
  /* native level = h1 */ $headline-6:
    mat.define-typography-level(
      $font-family: 'Title Font',
      $font-weight: 400,
      $font-size: 1.66rem,
      $line-height: 1,
      $letter-spacing: normal,
    ),
  /* native level = h3 */ $subtitle-1:
    mat.define-typography-level(
      $font-family: 'Title Font',
      $font-weight: 400,
      $font-size: 1.33rem,
      $line-height: 1,
      $letter-spacing: normal,
    ),
  /* native level = h4 */ $body-1:
    mat.define-typography-level(
      $font-family: 'Body Font',
      $font-weight: 400,
      $font-size: 1rem,
      $line-height: 1.11,
      $letter-spacing: normal,
    ),
  /* native level = body text */ $body-2:
    mat.define-typography-level(
      $font-family: 'Body Font',
      $font-weight: 400,
      $font-size: 1rem,
      $line-height: 1.5,
      $letter-spacing: normal,
    ),
  /* native level = body text */ $subtitle-2:
    mat.define-typography-level(
      $font-family: 'Body Font',
      $font-weight: 400,
      $font-size: 1rem,
      $line-height: 1.4,
      $letter-spacing: normal,
    ),
  $caption:
    mat.define-typography-level(
      $font-family: 'Body Font',
      $font-weight: 400,
      $font-size: 1rem,
      $line-height: 1,
      $letter-spacing: normal,
    )
);
@include mat.typography-hierarchy($humidor-typography-config);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$gdpr-tool-theme: mat.define-light-theme(
  (
    color: (
      primary: $gdpr-tool-primary,
      accent: $gdpr-tool-accent,
      warn: $gdpr-tool-warn,
    ),
    typography: $humidor-typography-config,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($gdpr-tool-theme);

@import './styles/styles';

html,
body {
  height: 100%;
  // overflow: hidden;
}
body {
  margin: 0;
  background: white;
}

/* CHAT */
.chat {
  .messages {
  }
}

li.message {
  padding: 1.33rem;
  border-radius: 1.33rem 1.33rem 1.33rem 0;

  color: white;
  .username {
    font-weight: bold;
  }

  .username,
  .sent_at {
    font-size: 0.66rem;
  }

  &.admin {
    background: black;
    background-color: black;
  }
  &.jurist {
    background: grey;
    background-color: grey;
  }
  &.member {
    background: green;
    background-color: green;
  }
  &.third-party {
    background: yellow;
    background-color: yellow;
  }
}
